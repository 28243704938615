/* eslint-disable array-bracket-newline */
import { Tooltip as ATooltip } from 'ant-design-vue'

import { CELL_MAX_WIDTH } from '@config'
import { fontSize } from '@assets/styles/varibles.scss'

import { isEmpty, isFunction, empty as emptyFilter } from '@utils'

export function renderTooltip(h, text, placement = 'topLeft') {
    return h(ATooltip, {
        props: {
            placement,
            title: text
        }
    }, [text])
}

export default function renderEllipsisCell(...params) {
    const [value, , , column, h] = params
    const text = emptyFilter(value)

    if (isEmpty(value) || !isFunction(h)) {
        return text
    }

    let ellipsis = column.ellipsis
    if (isEmpty(ellipsis)) {
        const textLen = text?.length || 0
        const cellWidth = column.width || CELL_MAX_WIDTH

        ellipsis = textLen * fontSize > cellWidth - 58
        column.ellipsis = ellipsis
    }

    return ellipsis ? renderTooltip(h, text) : text
}

<!-- 申请管理 - 备案申请 - 受理/详情弹框 - 审批内容 -->
<template>
    <fragment>
        <information-panel
            title="审批结果"
            :bordered="false"
            :loading="loading"
            class="has-margin-top"
            :columns="resultColumns"
            :data-source="resultInfo"
        />

        <a-card title="审批列表" class="has-margin-top">
            <custom-table
                :row-key="processListRowKey"
                :pagination="false"
                :loading="loading"
                :scroll="scrollConfig"
                :columns="processListColumns"
                :data-source="processListData"
            />
        </a-card>
    </fragment>
</template>
<script>
import { Card as ACard } from 'ant-design-vue'
import Fragment from '@components/Fragment'
import CustomTable from '@components/CustomTable'
import InformationPanel from '@components/InformationPanel'

import { RESULT_COLUMNS, PROCESS_LIST_COLUMNS, PROCESS_LIST_ROW_KEY } from './config'

import getDataService from '@service/approal/fillingApplication/getApplicationContentDataService'

export default {
    name: 'ApprovalContent',
    components: {
        ACard,
        Fragment,
        CustomTable,
        InformationPanel
    },
    props: {
        record: Object,
        visible: Boolean
    },
    data() {
        return {
            loading: false,
            resultInfo: {},
            processListData: [],

            scrollConfig: Object.freeze({
                x: 1000,
                y: 500
            }),

            processListRowKey: PROCESS_LIST_ROW_KEY,
            resultColumns: Object.freeze(RESULT_COLUMNS),
            processListColumns: Object.freeze(PROCESS_LIST_COLUMNS)
        }
    },
    watch: {
        'record.id': {
            handler(id) {
                this.getData(id)
            },
            immediate: true
        }
    },
    methods: {
        async getData(id) {
            try {
                this.loading = true

                // eslint-disable-next-line
                const result = await getDataService({ id })

                this.resultInfo = result
                this.processListData = result.processHistoryList || []
            } catch (e) {
                this.$message.warning(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

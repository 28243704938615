import renderPreviewFile from '@weights/customRender/renderPreviewFile'
import renderEllipsisCell from '@weights/customRender/renderEllipsisCell'

import { empty as emptyFilter } from '@utils'

export const RESULT_COLUMNS = [
    {
        dataIndex: 'approvalResultText',
        label: '审批结果',
        customRender: val => emptyFilter(val)
    }
]

export const PROCESS_LIST_ROW_KEY = (r, index) => index

export const PROCESS_LIST_COLUMNS = [
    {
        dataIndex: 'taskName',
        title: '审批流程',
        width: 200,
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'orgName',
        title: '组织名称',
        width: 240,
        customRender: renderEllipsisCell
    },
    {
        dataIndex: 'comment',
        title: '审批意见',
        width: 320,
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'fileJson',
        title: '附件',
        width: 180,
        customRender: renderPreviewFile
    },
    {
        dataIndex: 'approvalResultText',
        title: '操作内容',
        width: 120,
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'ownerName',
        title: '审批人',
        width: 150,
        customRender: val => emptyFilter(val)
    }
]
